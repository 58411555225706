import React from "react";
import PostItem from "../components/PostItem";
import {
  ImageContainer,
  Information,
  PostItemStyled,
} from "../components/PostItem/PostItem.styled";
import {
  PostListStyled,
  SectionStyled,
} from "../components/PostList/PostList.styled";
import SEO from "../components/SEO";
import { PeritajeStyles } from "../templates/Service/Service.styled";

const QuienesSomosPage = () => {
  return (
    <>
      <SEO title="Sobre nosotros" />

      <SectionStyled className="section">
        <div className="container">
          <PostListStyled>
            <PostItemStyled>
              <ImageContainer
                style={{
                  height: "279px",
                  borderRadius: "4px",
                  boxShadow:
                    "rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src="https://images-na.ssl-images-amazon.com/images/I/71leGpBePrL.jpg"
                />
              </ImageContainer>
              <Information>
                <h2>
                  Centrales térmicas de ciclo combinado: Teoría y proyecto
                </h2>
                <p>
                  <ul>
                    <li>
                      Publisher: Ediciones Díaz de Santos, S.A.; 1st edition
                      (February 6, 2006)
                    </li>
                    <li>Language: Spanish</li>
                    <li>Paperback: 328 pages</li>
                    <li>ISBN-10: 847978735X</li>
                    <li>ISBN-13: 978-8479787356</li>
                    <li>Item Weight: 1.5 pounds</li>
                    <li>UNSPSC-Code: 55101500</li>
                    <li>Dimensions: 6.69 x 0.59 x 9.45 inches</li>
                  </ul>
                </p>
              </Information>
            </PostItemStyled>
          </PostListStyled>
        </div>
      </SectionStyled>
    </>
  );
};

export default QuienesSomosPage;
